export const locales = [
  'en',
  'cs',
  'de',
  'es',
  'fr',
  'hr',
  'hu',
  'it',
  'iv',
  'ka',
  'mk',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sr',
  'sv',
  'tr',
] as const
export type Locale = (typeof locales)[number]
