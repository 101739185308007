'use client'

import { NextPage } from 'next'
import { ReactNode } from 'react'
import { store } from '@/redux/store'
import { Provider } from 'react-redux'

const Providers: NextPage<{ children: ReactNode }> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>
}
export default Providers
