import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'

import { PaymentMethod } from '@/types/payment'
import { AccessJwtPayload } from '@/types/user'

import AmexIcon from '../../public/assets/images/payment/amex.svg'
import ApplePay from '../../public/assets/images/payment/default.svg'
import DefaultIcon from '../../public/assets/images/payment/default.svg'
import GooglePay from '../../public/assets/images/payment/default.svg'
import Paypal from '../../public/assets/images/payment/default.svg'
import DinersClubIcon from '../../public/assets/images/payment/diners.svg'
import DiscoverIcon from '../../public/assets/images/payment/discover.svg'
import JcbIcon from '../../public/assets/images/payment/jcb.svg'
import MasterCardIcon from '../../public/assets/images/payment/mastercard.svg'
import VisaIcon from '../../public/assets/images/payment/visa.svg'

export const parseToken = (
  token: string | undefined
): AccessJwtPayload | undefined => {
  try {
    if (!token) return undefined
    return JSON.parse(atob(token.split('.')[1])) as AccessJwtPayload
  } catch (e) {
    return undefined
  }
}

export const setCookie = (cName: string, cValue: string, expDays = 400) => {
  const exp = new Date()
  exp.setTime(exp.getTime() + expDays * 24 * 60 * 60 * 1000)
  document.cookie = `${cName}=${cValue};expires=${exp.toUTCString()};path=/`
}
export const getCookieValue = (name: string) => {
  if (typeof document === 'undefined') return undefined
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1)
    }
  }
  return undefined
}
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9.-]+$/i
const nameRegex = /^.{5,}$/i

export const emailValidator = (email = '') => emailRegex.test(email)
export const nameValidator = (name = '') => nameRegex.test(name)

export const isNonNullish = <T>(x: T | null | undefined): x is T => x != null

export const isNonFalsy = <T>(x: T | null | undefined | false): x is T => !!x

export const isRtqError = <DataT, Error>(
  e: { data: DataT } | { error: Error }
): e is { error: Error } => (e as { error: Error }).error !== undefined

export const getCreditCardBrandSVG = (card: PaymentMethod) => {
  if (card.type === 'applePay') {
    return ApplePay
  }
  if (card.type === 'googlePay') {
    return GooglePay
  }
  switch (card.brand?.toLowerCase()) {
    case 'visa':
      return VisaIcon
    case 'american express':
    case 'american_express':
      return AmexIcon
    case 'mastercard':
    case 'master_card':
      return MasterCardIcon
    case 'discover':
      return DiscoverIcon
    case 'jcb':
      return JcbIcon
    case 'diners club':
      return DinersClubIcon
    case 'paypal':
      return Paypal
    case 'unknown':
    default:
      return DefaultIcon
  }
}

export const getJwtData = (token: string) => {
  const base64Url = token.split('.')[1] // Get payload segment
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/') + '=='
  const payload = Buffer.from(base64, 'base64').toString('utf8')
  return JSON.parse(payload)
}

export const getUserDetails = (cooks: ReadonlyRequestCookies) => {
  const token = cooks.get('access_token')

  let options = {
    hasPhoneNumber: false,
    HasEmail: false,
    HasName: false,
  }

  if (token) {
    const tokenData = parseToken(token.value)
    options.hasPhoneNumber = !!tokenData?.phoneNumber
    options.HasEmail = !!tokenData?.email
    options.HasName = !!(tokenData?.name && tokenData?.name !== 'Brick user')
  }

  return options
}
