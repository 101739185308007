import { Mutex } from 'async-mutex'

import { API_KEY, API_URL, APP_VERSION } from '@/lib/constants'
import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes'

import { RootState } from './store'

type BaseQueryFnCustom = BaseQueryFn<
  string | FetchArgs,
  unknown,
  ErrorResponse,
  {},
  FetchBaseQueryMeta
>

const mutex = new Mutex()

export type ErrorResponse<MetaData = { [key: string]: any }> = {
  data: {
    message: 'Forbidden' | string
    error: {
      message: string
      statusCode?: number
      err?: any
      function?: string
      body?: any
      userId?: string
      method?: string
      path?: string
      metaData?: MetaData
    }
  }
  status: number
}

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  credentials: 'include',
  mode: 'cors',
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).user
    if (token) {
      headers.set('Authorization', token)
    }
    headers.set('x-api-key', API_KEY)
    headers.set('app-version', APP_VERSION)
    return headers
  },
}) as BaseQueryFnCustom

const isRefresh = (args: FetchArgs | string) => {
  if (typeof args === 'string') {
    return args === 'auth/refresh'
  }
  return args.url === 'auth/refresh' && args.method === 'POST'
}

const fetchBaseFactory =
  (query: BaseQueryFnCustom) =>
  async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    await mutex.waitForUnlock()
    let result: QueryReturnValue<unknown, ErrorResponse, FetchBaseQueryMeta>
    const tokenData = (api.getState() as RootState).user.tokenData

    if (tokenData == null || tokenData.exp - 30 < Date.now() / 1000) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          const refreshResult = await baseQuery(
            {
              method: 'POST',
              url: `/auth/refresh`,
            },
            api,
            extraOptions
          )
          if (refreshResult.error && refreshResult.error.status === 401) {
            const loginResult = await baseQuery(
              {
                method: 'POST',
                url: `/auth/appless`,
              },
              api,
              extraOptions
            )
            api.dispatch({
              payload: loginResult.data,
              type: 'auth/setToken',
            })
          } else {
            api.dispatch({
              payload: refreshResult.data,
              type: 'auth/setToken',
            })
          }
        } finally {
          release()
        }
      } else {
        await mutex.waitForUnlock()
      }
    }
    result = await query(args, api, extraOptions)
    return result
  }
export const fetchBase = fetchBaseFactory(baseQuery)
