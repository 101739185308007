import { createSharedPathnamesNavigation } from 'next-intl/navigation'

import { locales } from './config'

export const { Link, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({
    locales,
  })

export const getApplessRedirectUrl = (path: string) => {
  const [locale = 'en', stationId] = window.location.pathname
    .slice(1)
    .split('/')
  const url = `${window.location.origin}/${locale}/${stationId}${path ?? ''}`
  return url
}
