'use client'
import { FC } from 'react'
import Image from 'next/image'
import LangLogo from '../../public/assets/images/languages.svg'
import { usePathname, useRouter } from '@/navigation'
import { LANGUAGES } from '@/lib/constants'

type Props = {
  locale: string
}

const Navigator: FC<Props> = ({ locale }) => {
  const router = useRouter()
  const pathname = usePathname()
  return (
    <label className="flex items-center">
      <Image
        className="ml-4 absolute"
        src={LangLogo}
        alt="lang logo"
        width={16}
      />
      <select
        id="select-language"
        className="p-4 pl-12 z-10 cursor-pointer"
        value={locale}
        onChange={(e) => {
          router.push(pathname, { locale: e.target.value })
        }}
      >
        {LANGUAGES.map((lang) => (
          <option key={lang.value} value={lang.value}>
            {lang.label}
          </option>
        ))}
      </select>
    </label>
  )
}

export default Navigator
