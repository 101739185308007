import {
  TypedUseSelectorHook,
  useDispatch as useDispatchVanilla,
  useSelector as useSelectorVanilla,
} from 'react-redux'

import { paymentApi } from '@/redux/payment/api'
import { stationApi } from '@/redux/station/api'
import { userApi } from '@/redux/user/api'
import userSlice from '@/redux/user/slice'
import {
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit'

const rootReducer = {
  user: userSlice,
  [userApi.reducerPath]: userApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [stationApi.reducerPath]: stationApi.reducer,
}

const rtkQueryErrorLogger: Middleware =
  (_api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.error(
        'Api error!',
        JSON.stringify(
          // @ts-ignore
          action?.payload?.data?.error ?? action?.payload?.error,
          null,
          1
        )
      )
    }
    return next(action)
  }

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({})
      .concat(userApi.middleware)
      .concat(paymentApi.middleware)
      .concat(stationApi.middleware)
      .concat(rtkQueryErrorLogger),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useDispatch = (): AppDispatch => useDispatchVanilla<AppDispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorVanilla
