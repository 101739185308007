import { User } from '@/types/user'
import { INTERCOM_APP_ID } from './constants'

export const openIntercom = () => {
  // @ts-ignore
  if (typeof window === 'undefined' || typeof window.Intercom === 'undefined')
    return

  // @ts-ignore
  const Intercom = window.Intercom

  if (Intercom?.booted) {
    Intercom('boot', {
      app_id: INTERCOM_APP_ID,
    })
  }

  Intercom('show')
}

export const updateIntercom = (user: User) => {
  // @ts-ignore
  if (typeof window === 'undefined' || typeof window.Intercom === 'undefined')
    return
  if (user == null) {
    // @ts-ignore
    window.Intercom('update', {})
  }
  // @ts-ignore
  window.Intercom('update', {
    user_id: user?.id,
    name: user?.name,
    email: user?.contactEmail ?? user.cognitoEmail,
    phone: user?.phoneNumber,
    created_at: user?.createdAt,
    ...user,
  })
}
