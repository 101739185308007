import { parseToken } from '@/lib/helpers'
import { updateIntercom } from '@/lib/intercom'
import { getApplessRedirectUrl } from '@/navigation'
import { userApi } from '@/redux/user/api'
import { AccessJwtPayload, AuthResponse, User } from '@/types/user'
/* eslint-disable no-param-reassign */
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

export type UserState = {
  token?: string
  tokenData?: AccessJwtPayload
  user?: User
}

const initialState: UserState = {
  token: undefined,
  tokenData: undefined,
  user: undefined,
}

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<AuthResponse>) => {
      updateCredentials(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApi.endpoints.refreshToken.matchFulfilled,
        (state, { payload }) => {
          updateCredentials(state, payload)
        }
      )
      .addMatcher(
        userApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          updateCredentials(state, payload)
        }
      )
      .addMatcher(
        userApi.endpoints.socialAuthCallback.matchFulfilled,
        (state, { payload }) => {
          updateCredentials(state, payload, getApplessRedirectUrl('/event'))
        }
      )
      .addMatcher(
        userApi.endpoints.verifyAuthToken.matchFulfilled,
        (state, { payload }) => {
          updateCredentials(state, payload)
        }
      )
  },
})

const updateCredentials = (
  state: Draft<UserState>,
  payload: AuthResponse,
  redirectUrl?: string
) => {
  state.tokenData = parseToken(payload.accessToken)
  state.token = payload.accessToken
  state.user = payload.user
  updateIntercom(payload.user)
  if (
    window.origin.includes('localhost:') ||
    window.origin.includes('127.0.0.1:')
  ) {
    document.cookie = `access_token=${payload.accessToken}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/; Domain=localhost; SameSite=Lax`
  }

  if (redirectUrl) {
    window.location.href = redirectUrl
  }
}

export const {} = slice.actions

export default slice.reducer
