import qs from 'querystring'

import {
  AuthResponse,
  CallbackForm,
  GetVerifyCode,
  LoginParams,
  User,
} from '@/types/user'
import { createApi } from '@reduxjs/toolkit/query/react'

import { fetchBase } from '../baseQuery'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBase,
  endpoints: (builder) => ({
    refreshToken: builder.query<{ accessToken: string; user: User }, void>({
      query: () => ({
        url: `auth/refresh`,
        method: 'POST',
      }),
    }),
    login: builder.query<{ accessToken: string; user: User }, void>({
      query: () => ({
        url: `auth/appless`,
        method: 'POST',
      }),
    }),
    signOut: builder.mutation<{ message: string }, void>({
      query: () => ({
        url: `auth/refresh`,
        method: 'DELETE',
      }),
    }),
    getAuthVerifyCode: builder.mutation<GetVerifyCode, string>({
      query: (phoneNumber) => ({
        url: '/auth/code',
        method: 'POST',
        body: {
          phoneNumber,
          noSend: false,
        },
      }),
    }),
    socialAuthCallback: builder.mutation<AuthResponse, CallbackForm>({
      query: (data) => ({
        url: `/auth/callback`,
        method: 'POST',
        body: qs.stringify(data),
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      }),
    }),
    verifyAuthToken: builder.mutation<AuthResponse, LoginParams>({
      query: (data) => ({
        url: `/auth/verify`,
        method: 'POST',
        body: data,
      }),
    }),
    updateUser: builder.mutation<
      { user: User },
      { name?: string; contactEmail?: string }
    >({
      query: (data) => ({
        url: `/user`,
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
})

export const {
  useLazyRefreshTokenQuery,
  useSocialAuthCallbackMutation,
  useVerifyAuthTokenMutation,
  useGetAuthVerifyCodeMutation,
  useUpdateUserMutation,
} = userApi
