import { createApi } from '@reduxjs/toolkit/query/react'

import { fetchBase } from '../baseQuery'

export const stationApi = createApi({
  reducerPath: 'stationApi',
  keepUnusedDataFor: 5,
  baseQuery: fetchBase,
  endpoints: (builder) => ({
    getLinkData: builder.query<{ station: { stationId: string } }, string>({
      query: (url) => ({
        url: 'stations/links',
        method: 'GET',
        params: {
          url,
        },
      }),
    }),
  }),
})

export const { useLazyGetLinkDataQuery } = stationApi
