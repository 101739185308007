import { createApi } from '@reduxjs/toolkit/query/react'

import { fetchBase } from '../baseQuery'
import { SetupIntent } from '@stripe/stripe-js'
import { PaymentMethod } from '@/types/payment'

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  keepUnusedDataFor: 5,
  baseQuery: fetchBase,
  endpoints: (builder) => ({
    createSetupIntent: builder.mutation<
      { intent: SetupIntent; ipAddress: string; userAgent: string },
      {
        type: 'paypal' | 'card' | 'ideal'
        methodId?: string
        noAttach?: boolean
        email?: string
      }
    >({
      query: ({ type, methodId, noAttach, email }) => ({
        url: '/payment/setupIntent',
        method: 'GET',
        params: { type, methodId, noAttach, email },
      }),
    }),
    attachMethod: builder.mutation<
      {
        paymentMethod: PaymentMethod
        cards: PaymentMethod[] // deprecated since 1.7.2
        paymentMethods: PaymentMethod[]
        alreadyExists: boolean
      },
      {
        methodId?: string
      }
    >({
      query: ({ methodId }) => ({
        url: '/payment/attachMethod',
        method: 'POST',
        body: { methodId },
      }),
    }),
    setDefaultPaymentMethod: builder.mutation<
      { paymentMethods: PaymentMethod[] },
      { id: string; provider: string }
    >({
      query: (card) => ({
        url: '/payment/customers',
        method: 'PATCH',
        body: {
          default_source: card.id,
          provider: card.provider,
        },
      }),
    }),
  }),
})

export const {
  useCreateSetupIntentMutation,
  useAttachMethodMutation,
  useSetDefaultPaymentMethodMutation,
} = paymentApi
